import type { BlocksContent } from "@strapi/blocks-react-renderer";
import { Heading } from "../base/heading";
import { HorizontalSpacer } from "../layout/horizontal-spacer";
import { Card } from "./card";
import RichTextJsonRenderer from "./rich-text-json-renderer";

interface CampaignBlockProps {
  jsonRichTextContent?: BlocksContent;
  title?: string;
}

export const CampaignBlock = ({ jsonRichTextContent, title }: CampaignBlockProps) => {
  return (
    <div className="campaign-block">
      <Card bgColor="fill">
        <div className="campaign-block__heading">
          {title && (
            <div className="campaign-block__heading__title">
              <Heading type="h2">{title}</Heading>
              <HorizontalSpacer />
            </div>
          )}

          <div className="campaign-block__heading__icon" />
        </div>

        {jsonRichTextContent && (
          <RichTextJsonRenderer content={jsonRichTextContent} textTheme="primary" alignTextHorizontally="left" />
        )}
      </Card>
    </div>
  );
};
