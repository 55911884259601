import { classNames } from "@/utils/styling-utils";
import type { ReactNode } from "react";

interface CardProps {
  bgColor?: "fill" | "light-blue";
  horizontalPlacement?: "left" | "center" | "right";
  children: ReactNode;
  height?: "full";
  width?: "full";
}

const cssClasses = (property: string, value?: string) => {
  if (!value) {
    return;
  }

  const className = `card__${property}--${value}`;
  return className;
};

export const Card = ({ bgColor, children, horizontalPlacement, height, width }: CardProps) => {
  const cssWidthClass = cssClasses("width", width);
  const cssHeightClass = cssClasses("height", height);
  const cssBackgroundColorClass = cssClasses("background-color", bgColor);

  return (
    <div
      className={classNames(
        "card",
        bgColor && "card--background-color",
        horizontalPlacement && `card__horizontal-placement--${horizontalPlacement}`,
        cssHeightClass,
        cssWidthClass,
        cssBackgroundColorClass,
      )}
    >
      {children}
    </div>
  );
};
