"use client";
import RichTextJsonRenderer from "@/components/ui/rich-text-json-renderer";
import { MediaQuery } from "@/contexts/media-context";
import { NoticeType, type NoticeWithMessageFragment } from "@/generated/client.generated";
import { classNames } from "@/utils/styling-utils";
import { CheckCircleIconSolid, ExclamationIcon } from "../icons/icons";
import { Text } from "./text";

interface NoticeWithMessageProps {
  parts: NoticeWithMessageFragment;
}

function getIconType(noticeType: NoticeType) {
  switch (noticeType) {
    case NoticeType.Error:
    case NoticeType.Warning:
      return <ExclamationIcon size="large" />;
    case NoticeType.Success:
      return <CheckCircleIconSolid size="large" color={"green"} />;
    default:
      return <CheckCircleIconSolid size="large" color={"blue"} />;
  }
}

export const NoticeWithMessage = ({ parts }: NoticeWithMessageProps) => {
  return (
    <div className={classNames(`notice notice--${parts.notice.toLowerCase()}`)}>
      <div className="notice__content">
        {getIconType(parts.notice)}
        <div>
          {parts.messageRichText ? (
            <>
              <MediaQuery greaterThanOrEqual="lg">
                <RichTextJsonRenderer
                  alignTextHorizontally={"left"}
                  textTheme="dark"
                  content={JSON.parse(parts.messageRichText)}
                />
              </MediaQuery>
              <MediaQuery lessThan="lg">
                <RichTextJsonRenderer
                  alignTextHorizontally={"center"}
                  textTheme="dark"
                  content={JSON.parse(parts.messageRichText)}
                />
              </MediaQuery>
            </>
          ) : (
            parts.nullableMessage && (
              <>
                <MediaQuery greaterThanOrEqual="lg">
                  <Text value={parts.nullableMessage ?? ""} weight="bold" align={undefined} />
                </MediaQuery>
                <MediaQuery lessThan="lg">
                  <Text value={parts.nullableMessage ?? ""} weight="bold" align={"center"} />
                </MediaQuery>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
